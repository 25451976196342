import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const initDataRaw = window.Telegram?.WebApp?.initData;

    if (initDataRaw) {
      axios.post("https://webappapi.vercel.app/validate_init_data", { initData: initDataRaw })
        .then(response => {
          if (response.data.status === "success") {
            setUserId(response.data.user_id);
          }
        })
        .catch(error => {
          console.error("Failed to validate initData:", error);
        });
    }
  }, []);

  return (
    <div>
      <h1>Telegram Web App</h1>
      {userId ? (
        <p>Received Telegram User ID: {userId}</p>
      ) : (
        <p>Waiting for initData...</p>
      )}
    </div>
  );
};

export default App;